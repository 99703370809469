import './App.css'

// assets/img/team/1.jpg

import Image1 from '../src/assets/img/portfolio/1.jpg'
import Image2 from '../src/assets/img/portfolio/2.jpg'
import Image3 from '../src/assets/img/portfolio/3.jpg'
import Image4 from '../src/assets/img/portfolio/4.jpg'
import Image5 from '../src/assets/img/portfolio/5.jpg'
import Image6 from '../src/assets/img/portfolio/6.jpg'
import Image7 from '../src/assets/img/portfolio/7.jpg'
import Image8 from '../src/assets/img/portfolio/8.jpg'
import Image9 from '../src/assets/img/portfolio/9.jpg'

import Team1 from '../src/assets/img/team/1.png'

import microsoft from '../src/assets/img/logos/sbaj_icon.jpg'

import { useEffect, useState } from 'react'

import emailjs from '@emailjs/browser'

function App () {
  const [UserName, setUserName] = useState('')
  const [UserEmail, setUserEmail] = useState('')
  const [UserPhoneNumber, setUserPhoneNumber] = useState('')
  const [UserMesage, setUserMesage] = useState('')

  useEffect(() => {
    //console.log('Deva')
  })

  const onSendMessageButton = async e => {
    e.preventDefault()
    if (
      UserName.length > 0 &&
      UserEmail.length > 0 &&
      UserPhoneNumber.length > 0 &&
      UserMesage.length > 0
    ) {
      // alert(
      //   UserName +
      //     ' - ' +
      //     UserEmail +
      //     ' - ' +
      //     UserPhoneNumber +
      //     ' - ' +
      //     UserMesage
      // )
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(UserEmail)) {
        //alert('yes')
        var templateParams = {
          UserName: UserName,
          UserEmail: UserEmail,
          UserPhoneNumber: UserPhoneNumber,
          UserMessage: UserMesage
        }

        await emailjs
          .send(
            'service_2vvdx11',
            'template_sbpkgxu',
            templateParams,
            '0wGJdTuNfJ46um4_s'
          )
          .then(
            function (response) {
              console.log('SUCCESS!', response.status, response.text)
              alert('SUCCESS!', response.status, response.text)
            },
            function (error) {
              console.log('FAILED...', error)
              alert('FAILED...', error)
            }
          )

        alert()
        setUserName('')
        setUserEmail('')
        setUserPhoneNumber('')
        setUserMesage('')
      } else {
        //alert('no')
      }
    } else {
      alert('Fill all the fields to send enquire')
    }
  }

  const OnUserNameChange = DATA => {
    setUserName(DATA)
  }

  const OnUserEmailChange = DATA => {
    setUserEmail(DATA)
  }

  const OnUserPhoneNumberChange = DATA => {
    setUserPhoneNumber(DATA)
  }

  const OnUserMessageChange = DATA => {
    setUserMesage(DATA)
  }

  return (
    <div className='App'>
      <nav
        className='navbar navbar-expand-lg navbar-dark fixed-top'
        id='mainNav'
      >
        <div className='container'>
          {/* <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarResponsive'
            aria-controls='navbarResponsive'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            Menu
            <i className='fas fa-bars ms-1'></i>
          </button> */}
          {/* <div className='collapse navbar-collapse' id='navbarResponsive'>
            <ul className='navbar-nav text-uppercase ms-auto py-4 py-lg-0'>
              <li className='nav-item'>
                <a className='nav-link' href='#services'>
                  Services
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#portfolio'>
                  Portfolio
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#about'>
                  About
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#team'>
                  Team
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#contact'>
                  Contact
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>

      <header className='masthead'>
        <div className='container'>
          <div className='masthead-subheading'>
            <p style={{ color: '#000000' }}>
              Jash Pharmaceuticals & Medical supplies
            </p>
          </div>
          <div className='masthead-heading'>
            <p style={{ color: '#000000' }}>
              Jash Pharmaceuticals & Medical supplies Pvt Ltd, situated in
              Chennai with a well-defined vision of becoming a prominent
              pharmaceutical supply chain industry across the world.
            </p>
          </div>
          <a className='btn btn-primary btn-xl text-uppercase' href='#services'>
            Tell Me More
          </a>
        </div>
      </header>

      <section className='page-section' id='services'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='section-heading text-uppercase'>Services</h2>
            <h3 className='section-subheading text-muted'>.</h3>
          </div>
          <div className='row text-center'>
            <div className='col-md-4'>
              <img className='img-fluid rounded' src={Image7} alt='...' />

              <h4 className='my-3'>Medical Drugs</h4>
              <p className='text-muted'>
                We provide a wide selection of generic and specialty medications
                that work well together to treat both acute and chronic
                diseases. Over-the-counter (OTC), specialty, and branded generic
                medications make up our product portfolio.
              </p>
            </div>
            <div className='col-md-4'>
              <img className='img-fluid rounded' src={Image8} alt='...' />
              <h4 className='my-3'>Medical Supplies</h4>
              <p className='text-muted'>
                A Reliable Supplier for your medical supply needs, . Everything
                from prevention to diagnosis and everything in between is
                offered in our extensive selection of medical supplies.
              </p>
            </div>
            <div className='col-md-4'>
              <img className='img-fluid rounded' src={Image9} alt='...' />
              <h4 className='my-3'>Surgical Instruments</h4>
              <p className='text-muted'>
                We provide a broad selection of high-quality surgical
                instruments. Our range of surgical devices includes both forceps
                and surgical clamps. Our instruments are still well known for
                their superiority, high calibre, and creativity.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='page-section bg-light' id='portfolio'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='section-heading text-uppercase'>Products</h2>
            <h3 className='section-subheading text-muted'>.</h3>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-sm-6 mb-4'>
              <div className='portfolio-item'>
                <a
                  className='portfolio-link'
                  data-bs-toggle='modal'
                  href='#portfolioModal1'
                >
                  <div className='portfolio-hover'>
                    <div className='portfolio-hover-content'>
                      <i className='fas fa-plus fa-3x'></i>
                    </div>
                  </div>
                  <img className='img-fluid' src={Image1} alt='...' />
                </a>
                <div className='portfolio-caption'>
                  <div className='portfolio-caption-heading'>
                    Medical Devices
                  </div>
                  <div className='portfolio-caption-subheading text-muted'></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 mb-4'>
              <div className='portfolio-item'>
                <a
                  className='portfolio-link'
                  data-bs-toggle='modal'
                  href='#portfolioModal2'
                >
                  <div className='portfolio-hover'>
                    <div className='portfolio-hover-content'>
                      <i className='fas fa-plus fa-3x'></i>
                    </div>
                  </div>
                  <img className='img-fluid' src={Image2} alt='...' />
                </a>
                <div className='portfolio-caption'>
                  <div className='portfolio-caption-heading'>
                    Surgical Instruments
                  </div>
                  <div className='portfolio-caption-subheading text-muted'></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 mb-4'>
              <div className='portfolio-item'>
                <a
                  className='portfolio-link'
                  data-bs-toggle='modal'
                  href='#portfolioModal3'
                >
                  <div className='portfolio-hover'>
                    <div className='portfolio-hover-content'>
                      <i className='fas fa-plus fa-3x'></i>
                    </div>
                  </div>
                  <img className='img-fluid' src={Image3} alt='...' />
                </a>
                <div className='portfolio-caption'>
                  <div className='portfolio-caption-heading'>
                    Medical Furniture
                  </div>
                  <div className='portfolio-caption-subheading text-muted'></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 mb-4 mb-lg-0'>
              <div className='portfolio-item'>
                <a
                  className='portfolio-link'
                  data-bs-toggle='modal'
                  href='#portfolioModal4'
                >
                  <div className='portfolio-hover'>
                    <div className='portfolio-hover-content'>
                      <i className='fas fa-plus fa-3x'></i>
                    </div>
                  </div>
                  <img className='img-fluid' src={Image4} alt='...' />
                </a>
                <div className='portfolio-caption'>
                  <div className='portfolio-caption-heading'>
                    Medical Disposables
                  </div>
                  <div className='portfolio-caption-subheading text-muted'></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 mb-4 mb-sm-0'>
              <div className='portfolio-item'>
                <a
                  className='portfolio-link'
                  data-bs-toggle='modal'
                  href='#portfolioModal5'
                >
                  <div className='portfolio-hover'>
                    <div className='portfolio-hover-content'>
                      <i className='fas fa-plus fa-3x'></i>
                    </div>
                  </div>
                  <img className='img-fluid' src={Image5} alt='...' />
                </a>
                <div className='portfolio-caption'>
                  <div className='portfolio-caption-heading'>
                    Over-the-counter Drugs
                  </div>
                  <div className='portfolio-caption-subheading text-muted'></div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <div className='portfolio-item'>
                <a
                  className='portfolio-link'
                  data-bs-toggle='modal'
                  href='#portfolioModal6'
                >
                  <div className='portfolio-hover'>
                    <div className='portfolio-hover-content'>
                      <i className='fas fa-plus fa-3x'></i>
                    </div>
                  </div>
                  <img className='img-fluid' src={Image6} alt='...' />
                </a>
                <div className='portfolio-caption'>
                  <div className='portfolio-caption-heading'>
                    Speciality Drugs
                  </div>
                  <div className='portfolio-caption-subheading text-muted'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='page-section' id='about'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='section-heading text-uppercase'>About</h2>
            <h3 className='section-subheading text-muted'>
              Lorem ipsum dolor sit amet consectetur.
            </h3>
          </div>
          <ul className='timeline'>
            <li>
              <div className='timeline-image'>
                <img
                  className='rounded-circle img-fluid'
                  src={AboutImage1}
                  alt='...'
                />
              </div>
              <div className='timeline-panel'>
                <div className='timeline-heading'>
                  <h4>2009-2011</h4>
                  <h4 className='subheading'>Our Humble Beginnings</h4>
                </div>
                <div className='timeline-body'>
                  <p className='text-muted'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sunt ut voluptatum eius sapiente, totam reiciendis
                    temporibus qui quibusdam, recusandae sit vero unde, sed,
                    incidunt et ea quo dolore laudantium consectetur!
                  </p>
                </div>
              </div>
            </li>
            <li className='timeline-inverted'>
              <div className='timeline-image'>
                <img
                  className='rounded-circle img-fluid'
                  src={AboutImage2}
                  alt='...'
                />
              </div>
              <div className='timeline-panel'>
                <div className='timeline-heading'>
                  <h4>March 2011</h4>
                  <h4 className='subheading'>An Agency is Born</h4>
                </div>
                <div className='timeline-body'>
                  <p className='text-muted'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sunt ut voluptatum eius sapiente, totam reiciendis
                    temporibus qui quibusdam, recusandae sit vero unde, sed,
                    incidunt et ea quo dolore laudantium consectetur!
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className='timeline-image'>
                <img
                  className='rounded-circle img-fluid'
                  src={AboutImage3}
                  alt='...'
                />
              </div>
              <div className='timeline-panel'>
                <div className='timeline-heading'>
                  <h4>December 2015</h4>
                  <h4 className='subheading'>Transition to Full Service</h4>
                </div>
                <div className='timeline-body'>
                  <p className='text-muted'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sunt ut voluptatum eius sapiente, totam reiciendis
                    temporibus qui quibusdam, recusandae sit vero unde, sed,
                    incidunt et ea quo dolore laudantium consectetur!
                  </p>
                </div>
              </div>
            </li>
            <li className='timeline-inverted'>
              <div className='timeline-image'>
                <img
                  className='rounded-circle img-fluid'
                  src={AboutImage4}
                  alt='...'
                />
              </div>
              <div className='timeline-panel'>
                <div className='timeline-heading'>
                  <h4>July 2020</h4>
                  <h4 className='subheading'>Phase Two Expansion</h4>
                </div>
                <div className='timeline-body'>
                  <p className='text-muted'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sunt ut voluptatum eius sapiente, totam reiciendis
                    temporibus qui quibusdam, recusandae sit vero unde, sed,
                    incidunt et ea quo dolore laudantium consectetur!
                  </p>
                </div>
              </div>
            </li>
            <li className='timeline-inverted'>
              <div className='timeline-image'>
                <h4>
                  Be Part
                  <br />
                  Of Our
                  <br />
                  Story!
                </h4>
              </div>
            </li>
          </ul>
        </div>
      </section> */}

      <section className='page-section bg-light' id='team'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='section-heading text-uppercase'>Founder</h2>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4'>
              <div className='team-member'>
                <img className='mx-auto rounded-circle' src={Team1} alt='...' />
                <h4>Dr. Sangameswaran MBBS., D.Ortho., M.S. Ortho.,</h4>
                <p className='text-muted'>Founder Of Jash Pharmaceuticals</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-8 mx-auto text-center'>
              <p className='large text-muted'>.</p>
            </div>
          </div>
        </div>
      </section>

      <div className='py-5'>
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className='text-center'>
              <h2 className='section-heading text-uppercase'>Parteners</h2>
            </div>
            <div className='col-md-3 col-sm-6 my-3 '>
              <a href='https://www.sangambonehospital.com'>
                <img
                  className='img-fluid img-brand d-block mx-auto'
                  src={microsoft}
                  alt='...'
                  aria-label='Microsoft Logo'
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <section className='page-section' id='contact'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='section-heading text-uppercase'>Contact Us</h2>
            <h3 className='section-subheading '>
              No: 101, 8th block, Kavimani Salai, Mogappair west, Chennai
              600037.
              <br />
              Email : contact@jpms.one
            </h3>
          </div>
          {/* <!-- * * * * * * * * * * * * * * *-->
                <!-- * * SB Forms Contact Form * *-->
                <!-- * * * * * * * * * * * * * * *-->
                <!-- This form is pre-integrated with SB Forms.-->
                <!-- To make this form functional, sign up at-->
                <!-- https://startbootstrap.com/solution/contact-forms-->
                <!-- to get an API token!--> */}
          <form id='contactForm' data-sb-form-api-token='API_TOKEN'>
            <div className='row align-items-stretch mb-5'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <input
                    className='form-control'
                    id='name'
                    type='text'
                    placeholder='Your Name *'
                    data-sb-validations='required'
                    onChange={evt => {
                      OnUserNameChange(evt.target.value)
                    }}
                    value={UserName}
                  />
                  <div
                    className='invalid-feedback'
                    data-sb-feedback='name:required'
                  >
                    A name is required.
                  </div>
                </div>
                <div className='form-group'>
                  <input
                    className='form-control'
                    id='email'
                    type='email'
                    placeholder='Your Email *'
                    data-sb-validations='required,email'
                    onChange={evt => {
                      OnUserEmailChange(evt.target.value)
                    }}
                    value={UserEmail}
                  />
                  <div
                    className='invalid-feedback'
                    data-sb-feedback='email:required'
                  >
                    An email is required.
                  </div>
                  <div
                    className='invalid-feedback'
                    data-sb-feedback='email:email'
                  >
                    Email is not valid.
                  </div>
                </div>
                <div className='form-group mb-md-0'>
                  <input
                    className='form-control'
                    id='phone'
                    type='tel'
                    placeholder='Your Phone *'
                    data-sb-validations='required'
                    onChange={evt => {
                      OnUserPhoneNumberChange(evt.target.value)
                    }}
                    value={UserPhoneNumber}
                  />
                  <div
                    className='invalid-feedback'
                    data-sb-feedback='phone:required'
                  >
                    A phone number is required.
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group form-group-textarea mb-md-0'>
                  <textarea
                    className='form-control'
                    id='message'
                    placeholder='Enter Your Message Here . . . . . '
                    onChange={evt => {
                      OnUserMessageChange(evt.target.value)
                    }}
                    value={UserMesage}
                  ></textarea>
                  <div
                    className='invalid-feedback'
                    data-sb-feedback='message:required'
                  >
                    A message is required.
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Submit success message-->
                    <!---->
                    <!-- This is what your users will see when the form-->
                    <!-- has successfully submitted--> */}
            <div className='d-none' id='submitSuccessMessage'>
              <div className='text-center text-white mb-3'>
                <div className='fw-bolder'>Form submission successful!</div>
                To activate this form, sign up at
                <br />
                <a href='https://startbootstrap.com/solution/contact-forms'>
                  https://startbootstrap.com/solution/contact-forms
                </a>
              </div>
            </div>
            {/* <!-- Submit error message-->
                    <!---->
                    <!-- This is what your users will see when there is-->
                    <!-- an error submitting the form--> */}
            <div className='d-none' id='submitErrorMessage'>
              <div className='text-center text-danger mb-3'>
                Error sending message!
              </div>
            </div>

            <div className='text-center'>
              <button
                className='btn btn-primary btn-xl text-uppercase'
                id='submitButton'
                type='submit'
                onClick={onSendMessageButton}
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>

      <footer className='footer py-4'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-4 text-lg-start'>
              Copyright &copy; JPMS 2023
            </div>
            {/* <div className='col-lg-4 my-3 my-lg-0'>
              <a
                className='btn btn-dark btn-social mx-2'
                href='#!'
                aria-label='Twitter'
              >
                <i className='fab fa-twitter'></i>
              </a>
              <a
                className='btn btn-dark btn-social mx-2'
                href='#!'
                aria-label='Facebook'
              >
                <i className='fab fa-facebook-f'></i>
              </a>
              <a
                className='btn btn-dark btn-social mx-2'
                href='#!'
                aria-label='LinkedIn'
              >
                <i className='fab fa-linkedin-in'></i>
              </a>
            </div> */}
            <div className='col-lg-4 text-lg-end'>
              {/* <a className='link-dark text-decoration-none me-3' href='#!'>
                Privacy Policy
              </a>
              <a className='link-dark text-decoration-none' href='#!'>
                Terms of Use
              </a> */}
            </div>
          </div>
        </div>
      </footer>
      <div
        className='portfolio-modal modal fade'
        id='portfolioModal1'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='close-modal' data-bs-dismiss='modal'>
              <img src='assets/img/close-icon.svg' alt='Close modal' />
            </div>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-8'>
                  <div className='modal-body'>
                    <h2 className='text-uppercase'>Project Name</h2>
                    <p className='item-intro text-muted'>
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className='img-fluid d-block mx-auto'
                      src={Image1}
                      alt='...'
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className='list-inline'>
                      <li>
                        <strong>Client:</strong>
                        Threads
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Illustration
                      </li>
                    </ul>
                    <button
                      className='btn btn-primary btn-xl text-uppercase'
                      data-bs-dismiss='modal'
                      type='button'
                    >
                      <i className='fas fa-xmark me-1'></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='portfolio-modal modal fade'
        id='portfolioModal2'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='close-modal' data-bs-dismiss='modal'>
              <img src='assets/img/close-icon.svg' alt='Close modal' />
            </div>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-8'>
                  <div className='modal-body'>
                    <h2 className='text-uppercase'>Project Name</h2>
                    <p className='item-intro text-muted'>
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className='img-fluid d-block mx-auto'
                      src={Image2}
                      alt='...'
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className='list-inline'>
                      <li>
                        <strong>Client:</strong>
                        Explore
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Graphic Design
                      </li>
                    </ul>
                    <button
                      className='btn btn-primary btn-xl text-uppercase'
                      data-bs-dismiss='modal'
                      type='button'
                    >
                      <i className='fas fa-xmark me-1'></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='portfolio-modal modal fade'
        id='portfolioModal3'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='close-modal' data-bs-dismiss='modal'>
              <img src='assets/img/close-icon.svg' alt='Close modal' />
            </div>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-8'>
                  <div className='modal-body'>
                    <h2 className='text-uppercase'>Project Name</h2>
                    <p className='item-intro text-muted'>
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className='img-fluid d-block mx-auto'
                      src={Image3}
                      alt='...'
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className='list-inline'>
                      <li>
                        <strong>Client:</strong>
                        Finish
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Identity
                      </li>
                    </ul>
                    <button
                      className='btn btn-primary btn-xl text-uppercase'
                      data-bs-dismiss='modal'
                      type='button'
                    >
                      <i className='fas fa-xmark me-1'></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='portfolio-modal modal fade'
        id='portfolioModal4'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='close-modal' data-bs-dismiss='modal'>
              <img src='assets/img/close-icon.svg' alt='Close modal' />
            </div>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-8'>
                  <div className='modal-body'>
                    <h2 className='text-uppercase'>Project Name</h2>
                    <p className='item-intro text-muted'>
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className='img-fluid d-block mx-auto'
                      src={Image4}
                      alt='...'
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className='list-inline'>
                      <li>
                        <strong>Client:</strong>
                        Lines
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Branding
                      </li>
                    </ul>
                    <button
                      className='btn btn-primary btn-xl text-uppercase'
                      data-bs-dismiss='modal'
                      type='button'
                    >
                      <i className='fas fa-xmark me-1'></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='portfolio-modal modal fade'
        id='portfolioModal5'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='close-modal' data-bs-dismiss='modal'>
              <img src='assets/img/close-icon.svg' alt='Close modal' />
            </div>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-8'>
                  <div className='modal-body'>
                    <h2 className='text-uppercase'>Project Name</h2>
                    <p className='item-intro text-muted'>
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className='img-fluid d-block mx-auto'
                      src={Image5}
                      alt='...'
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className='list-inline'>
                      <li>
                        <strong>Client:</strong>
                        Southwest
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Website Design
                      </li>
                    </ul>
                    <button
                      className='btn btn-primary btn-xl text-uppercase'
                      data-bs-dismiss='modal'
                      type='button'
                    >
                      <i className='fas fa-xmark me-1'></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='portfolio-modal modal fade'
        id='portfolioModal6'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='close-modal' data-bs-dismiss='modal'>
              <img src='assets/img/close-icon.svg' alt='Close modal' />
            </div>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-8'>
                  <div className='modal-body'>
                    <h2 className='text-uppercase'>Project Name</h2>
                    <p className='item-intro text-muted'>
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className='img-fluid d-block mx-auto'
                      src={Image6}
                      alt='...'
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className='list-inline'>
                      <li>
                        <strong>Client:</strong>
                        Window
                      </li>
                      <li>
                        <strong>Category:</strong>
                        Photography
                      </li>
                    </ul>
                    <button
                      className='btn btn-primary btn-xl text-uppercase'
                      data-bs-dismiss='modal'
                      type='button'
                    >
                      <i className='fas fa-xmark me-1'></i>
                      Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
